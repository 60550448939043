<template>
  <div class="fixed inset-0 overflow-hidden add-live-account-vue">
    <div class="absolute inset-0 overflow-hidden">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form @submit.prevent="handleSubmit(addAccount)" @reset.prevent="reset">
          <section
            class="absolute inset-y-0 right-0 pl-0 sm:pl-10 max-w-full flex"
          >
            <div class="w-screen max-w-none sm:max-w-lg">
              <div
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
              >
                <div
                  class="min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"
                >
                  <header class="px-8 py-6 bg-gray-100">
                    <div>
                      <div class="flex justify-between">
                        <h3 class="text-lg leading-6 font-normal text-gray-900">
                          {{ $t("add-a-new-trading-account") }}
                        </h3>
                      </div>
                      <p
                        v-if="
                          (whitelabel === 'TDSouthAfrica' ||
                            whitelabel === 'TD365') &&
                          $store.getters.get_live_backend_accounts.length === 0
                        "
                        class="mt-1 max-w-2xl text-sm font-light text-gray-500"
                      >
                        {{
                          $t("sorry-you-cannot-create-more-trading-accounts")
                        }}
                      </p>
                      <p
                        v-else-if="
                          ($store.getters.get_live_backend_accounts.length ===
                            0 ||
                            $store.getters.get_live_accounts.length >= 1) &&
                          whitelabel === 'GCCBrokers'
                        "
                        class="mt-1 max-w-2xl text-sm font-light text-gray-500"
                      >
                        {{
                          $t("sorry-you-cannot-create-more-trading-accounts")
                        }}
                      </p>
                      <p
                        v-else
                        class="mt-1 max-w-2xl text-sm font-light text-gray-500"
                      >
                        <span
                          v-if="$store.getters.get_profile.state === 'Full'"
                        >
                          {{
                            $t(
                              "choose-your-desired-trading-platform-and-currency-settings",
                            )
                          }}
                        </span>
                      </p>
                      <div
                        class="h-7 flex items-center absolute top-5 right-5"
                        @click="$emit('close')"
                      >
                        <button
                          aria-label="Close panel"
                          class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                        >
                          <!-- Heroicon name: x -->
                          <svg
                            class="h-5 w-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 18L18 6M6 6l12 12"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </header>
                  <div
                    v-if="
                      $store.getters.get_profile.profile.hasOwnProperty(
                        'limits',
                      )
                    "
                  >
                    <div
                      v-if="
                        $store.getters.get_profile.profile.limits !== null &&
                        checkNumberOfAccounts($store.getters.get_profile) >=
                          $store.getters.get_profile.profile.limits.accounts
                      "
                    >
                      <p class="px-6 py-6">
                        Sorry, you cannot create more trading accounts untill
                        you upgrade your account
                      </p>
                      {{ showTheContent(false) }}
                    </div>
                    <div v-else>
                      {{ showTheContent(true) }}
                    </div>
                  </div>
                  <div
                    v-if="showContent"
                    class="relative flex-1 mt-0 sm:p-0 p-6"
                  >
                    <!-- Replace with your content -->
                    <div>
                      <!--            Message when profile isn't complete v-if="$store.getters.get_profile.state !== 'Full'"-->
                      <complete-id-identification
                        v-if="
                          $store.getters.get_profile.state !== 'Full' &&
                          $store.getters.get_profile.state !== 'Limited'
                        "
                        title=""
                      ></complete-id-identification>
                      <div
                        v-else-if="
                          !(
                            $store.getters.get_live_accounts.length >= 1 &&
                            whitelabel === 'GCCBrokers'
                          )
                        "
                        class="sm:px-0 pt-8"
                      >
                        <dl
                          v-if="
                            $store.getters.get_live_backend_accounts.length > 0
                          "
                        >
                          <div class="sm:px-8 sm:py-2">
                            <dt class="mb-1 text-sm leading-5 font-normal">
                              {{ $t("select-account") }}
                            </dt>
                            <dd
                              class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2 flex">
                                <button
                                  v-for="(
                                    account, index
                                  ) in get_live_backend_accounts"
                                  id="account_1"
                                  :key="index"
                                  :class="
                                    selectedAccount === account
                                      ? 'border-blue-500'
                                      : ''
                                  "
                                  class="cursor-pointer p-5 border rounded mr-3 w-full focus:outline-none"
                                  type="reset"
                                  @click="setAccount(account)"
                                >
                                  <img
                                    v-if="
                                      account.backend.name ===
                                      'Cloud-SingleCurrencyTrading'
                                    "
                                    :src="
                                      selectedAccount === account
                                        ? require(
                                            `../../assets/icon-${account.backend.name}-white.png`,
                                          )
                                        : require(
                                            `../../assets/icon-${account.backend.name}.png`,
                                          )
                                    "
                                    alt="Meta Logo"
                                    class="m-auto"
                                  />
                                  <img
                                    v-else
                                    :src="
                                      selectedAccount === account
                                        ? require(
                                            `../../assets/${account.backend.type}-white.png`,
                                          )
                                        : require(
                                            `../../assets/${account.backend.type}.png`,
                                          )
                                    "
                                    alt="Meta Logo"
                                    class="m-auto"
                                  />
                                </button>
                              </div>
                            </dd>
                          </div>
                          <div v-if="selectedAccount.backend">
                            <div class="sm:px-8 mt-5">
                              <dt class="mb-1 text-sm leading-5 font-normal">
                                {{ $t("Currency") }}
                              </dt>
                              <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                              >
                                <div class="sm:col-span-2">
                                  <ValidationProvider
                                    v-if="selectedAccount.backend"
                                    v-slot="{ errors }"
                                    name="password_current"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="currency"
                                      :options="
                                        get_live_backend_accounts.find(
                                          (x) =>
                                            x.backend ===
                                            selectedAccount.backend,
                                        ).currencies
                                      "
                                      :placeholder="
                                        $t(
                                          'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11',
                                        )
                                      "
                                      label="symbol"
                                    ></v-select>
                                    <span class="text-sm text-red-600 h-4">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                              </dd>
                            </div>
                          </div>
                          <div
                            v-if="
                              whitelabel === 'ICMEU' ||
                              whitelabel === 'ICMTrader' ||
                              whitelabel === 'ICMMENA'
                            "
                            class="sm:px-8 mt-5 mb-1"
                          >
                            <dt class="text-sm leading-5 font-normal">
                              {{ $t("Leverage") }}
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="leverage"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="leverage"
                                    :options="leverages"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11',
                                      )
                                    "
                                    label="name"
                                  ></v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <template
                            v-if="
                              whitelabel === 'OneTradingMarkets' &&
                              selectedAccount.backend
                            "
                          >
                            <div
                              v-if="selectedAccount.backend.name === 'MT5'"
                              class="sm:px-8 mt-5 mb-1"
                            >
                              <dt class="mb-1 text-sm leading-5 font-normal">
                                {{ $t("account-type") }}
                              </dt>
                              <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                              >
                                <div class="sm:col-span-2">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="currency"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="accountType"
                                      :options="accountTypesOTM"
                                      :placeholder="
                                        $t(
                                          'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11',
                                        )
                                      "
                                      label="symbol"
                                    ></v-select>
                                    <span class="text-sm text-red-600 h-4">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                              </dd>
                            </div>
                          </template>
                          <div
                            v-if="
                              whitelabel !== 'TD365' &&
                              whitelabel !== 'TDSouthAfrica' &&
                              whitelabel !== 'OneTradingMarkets' &&
                              whitelabel !== 'EagleGlobalMarkets' &&
                              whitelabel !== 'TradeCoreUK' &&
                              whitelabel !== 'VCGMarkets'
                            "
                            class="sm:px-8 mt-5 mb-1"
                          >
                            <dt class="mb-1 text-sm leading-5 font-normal">
                              {{ $t("account-type") }}
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="currency"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="accountType"
                                    :options="accountTypes"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11',
                                      )
                                    "
                                    label="symbol"
                                  ></v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div v-if="selectedAccount.backend">
                            <div
                              v-if="
                                (whitelabel === 'ICMVC' ||
                                  whitelabel === 'ICMAP' ||
                                  whitelabel === 'ICMMU' ||
                                  whitelabel === 'ICMMENA') &&
                                (selectedAccount.backend.type === 'MT5' ||
                                  selectedAccount.backend.type === 'MT4')
                              "
                              class="sm:px-8 mt-5 mb-1"
                            >
                              <dt class="mb-1 text-sm leading-5 font-normal">
                                {{ $t("agent-number") }}
                              </dt>
                              <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                              >
                                <div class="sm:col-span-2">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    name="agent-number"
                                    rules="max_value_agent:10"
                                  >
                                    <input
                                      v-model="agentNumber"
                                      :placeholder="$t('agent-number')"
                                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                      min="0"
                                      oninput="validity.valid||(value='');"
                                      type="number"
                                    />
                                    <span class="text-sm text-red-600 h-4">{{
                                      errors[0]
                                    }}</span>
                                  </ValidationProvider>
                                </div>
                              </dd>
                            </div>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showContent">
                  <div
                    v-if="$store.getters.get_live_backend_accounts.length > 0"
                    class="flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"
                  >
                    <span class="inline-flex rounded-md shadow-sm ml-3">
                      <button
                        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                        type="reset"
                        @click="$emit('close')"
                      >
                        {{ $t("Cancel") }}
                      </button>
                    </span>
                    <span
                      v-if="
                        $store.getters.get_profile.state === 'Full' ||
                        $store.getters.get_profile.state === 'Limited'
                      "
                      class="inline-flex rounded-md shadow-sm"
                    >
                      <Button
                        :disabled="
                          isDisabled ||
                          ($store.getters.get_live_accounts.length >= 1 &&
                            whitelabel === 'GCCBrokers')
                        "
                        class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                        type="submit"
                      >
                        {{ $t("create-account") }}
                      </Button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SelectMenu from "@/components/SelectMenu";
import { get_live_backend_accounts } from "@/store/getters";
import { account_data } from "@/store/actions";
import CompleteIdIdentification from "@/components/CompleteIdIdentification";
import { accountTypes, accountTypesOTM, leverage } from "@/views/choices";

export default {
  name: "AddLiveAccount",
  components: {
    SelectMenu,
    CompleteIdIdentification,
  },
  data() {
    return {
      selectedAccount: {},
      currency: "",
      accountType: "",
      leverage: "",
      leverages: leverage(),
      agentNumber: "",
      isDisabled: false,
      whitelabel: process.env.VUE_APP_WHITELABEL,
      accountTypes: accountTypes(),
      accountTypesOTM: accountTypesOTM(),
      showContent: true,
    };
  },
  computed: {
    ...mapGetters(["get_live_backend_accounts", "get_account_data", ""]),
  },
  created() {
    this.$store.dispatch("get_backends").then(() => {
      if (this.get_live_backend_accounts) {
        // console.log(this.get_live_backend_accounts);
        this.selectedAccount = this.get_live_backend_accounts[0];
      }
    });
    this.$store.dispatch("user_data");
  },
  methods: {
    addAccount() {
      this.isDisabled = true;
      let backendData;
      if (
        this.whitelabel === "TD365" ||
        this.whitelabel === "TDSouthAfrica" ||
        this.whitelabel === "OneTradingMarkets" ||
        this.whitelabel === "EagleGlobalMarkets" ||
        this.whitelabel === "TradeCoreUK"
      ) {
        backendData = this.selectedAccount.backend.id;
      } else {
        backendData = this.selectedAccount.backend.name;
      }
      const data = {
        backend: backendData,
        currency: this.currency,
        group: null,
      };
      if (
        (this.whitelabel === "EagleGlobalMarkets" ||
          this.whitelabel === "TradeCoreUK") &&
        data.currency === ""
      ) {
        data.currency = "USD";
      }
      if (this.agentNumber.length > 0 && this.whitelabel === "ICMVC") {
        data.agent = this.agentNumber;
      }
      if (
        this.whitelabel === "ICMEU" ||
        this.whitelabel === "ICMTrader" ||
        this.whitelabel === "ICMMENA"
      ) {
        data.leverage = this.leverage.value;
      }
      if (
        this.whitelabel !== "TD365" &&
        this.whitelabel !== "OneTradingMarkets" &&
        this.whitelabel !== "EagleGlobalMarkets" &&
        this.whitelabel !== "TradeCoreUK"
      ) {
        data.account_type = this.accountType;
      }
      if (this.whitelabel === "OneTradingMarkets") {
        if (this.selectedAccount.backend.name === "MT5") {
          data.account_type = this.accountType;
        } else {
          delete data.account_type;
        }
      }
      if (this.whitelabel === "ICMMENA") {
        if (this.accountType === "ICM Zero") {
          if (this.selectedAccount.backend.type === "MT4") {
            if (this.currency === "USD") {
              data.group = "ICMME3R_AD";
            } else if (this.currency === "EUR") {
              data.group = "ICMME3RE_AD";
            } else if (this.currency === "GBP") {
              data.group = "ICMME3RGB_AD";
            }
          } else if (this.selectedAccount.backend.type === "MT5") {
            data.group = "real\\ICMMER1_AD";
          } else if (this.selectedAccount.backend.type === "Ctrader") {
            data.group = "ICMMER_AD";
          }
        } else if (this.accountType === "Direct") {
          if (this.selectedAccount.backend.type === "MT4") {
            if (this.currency === "USD") {
              data.group = "ICMME3D_AD";
            } else if (this.currency === "EUR") {
              data.group = "ICMME3DE_AD";
            } else if (this.currency === "GBP") {
              data.group = "ICMME3DGB_AD";
            }
          } else if (this.selectedAccount.backend.type === "MT5") {
            data.group = "real\\ICMMED_AD";
          } else if (this.selectedAccount.backend.type === "Ctrader") {
            data.group = "ICMMED_AD";
          }
        }
      }

      if (this.whitelabel === "GCCBrokers") {
        if (
          window.location.hostname.includes("localhost") ||
          window.location.hostname.includes("dev.") ||
          window.location.hostname.includes("staging.") ||
          window.location.hostname.includes("madetight.io")
        ) {
          data.group = "demo\\tc-test";
        } else {
          if (
            this.accountType === "Pro" &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            if (this.currency === "USD") {
              data.group = "real\\PRO-A100-US";
            } else if (this.currency === "EUR") {
              data.group = "real\\PRO-A100-EU";
            }
          }
          if (
            this.accountType === "Standard" &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            if (this.currency === "USD") {
              data.group = "real\\STD-A100-US";
            } else if (this.currency === "EUR") {
              data.group = "real\\STD-A100-EU";
            }
          }
        }
      }
      this.$store
        .dispatch("add_account", data)
        .then(() => {
          this.$emit("close");
          this.isDisabled = false;
          this.$store.dispatch("account_data");
          this.$notify({
            group: "foo",
            text: `${this.$t(
              "trading-account-creation-request-sent-to-our-team",
            )}`,
          });
        })
        .catch((err) => {
          this.isDisabled = false;
          if (
            Object.values(err.data)[0][0].text ===
              "User can`t have more than 1 trading accounts on MT4 trading platform. Please contact support." ||
            Object.values(err.data)[0][0].text ===
              "User can`t have more than 3 trading accounts on MT4 trading platform. Please contact support." ||
            Object.values(err.data)[0][0].text ===
              "Trading Account creation failed. Please contact support"
          ) {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)[0][0].text}`,
              type: "warn",
            });
          } else {
            this.$notify({
              group: "foo",
              text: `${Object.entries(err.data)[0][1]}`,
              type: "warn",
            });
          }
        });
    },
    setAccount(backend) {
      if (this.selectedAccount !== backend) {
        this.currency = "";
        this.accountType = "";
        this.leverage = "";
        this.agentNumber = "";
      }
      this.selectedAccount = backend;
    },
    showTheContent(value) {
      this.showContent = value;
    },
    // this function will return the number of trading accounts with a type "live" and a status "active"
    checkNumberOfAccounts(data) {
      if (
        !data ||
        !data.trading_accounts ||
        !Array.isArray(data.trading_accounts)
      ) {
        return 0; // Return 0 if 'data' or 'trading_accounts' is missing or not an array
      }

      const activeLiveAccounts = data.trading_accounts.filter((account) => {
        return account.type === "Live" && account.status === "active";
      });

      return activeLiveAccounts.length;
    },
  },
};
</script>
