var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed inset-0 overflow-hidden add-demo-account-vue"},[_c('div',{staticClass:"absolute inset-0 overflow-hidden"},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addAccount)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('section',{staticClass:"absolute inset-y-0 right-0 pl-0 sm:pl-10 max-w-full flex"},[_c('div',{staticClass:"w-screen max-w-none sm:max-w-lg"},[_c('div',{staticClass:"h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"},[_c('div',{staticClass:"min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"},[_c('header',{staticClass:"py-6 px-8 bg-gray-100"},[_c('div',[_c('div',{staticClass:"flex justify-between"},[_c('h3',{staticClass:"text-lg leading-6 font-normal text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("add-a-new-demo-account"))+" ")])]),(
                        _vm.$store.getters.get_demo_backend_accounts.length === 0
                      )?_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("sorry-you-cannot-have-any-more-demo-accounts"))+" ")]):_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t( "choose-your-desired-trading-platform-and-currency-settings-for-your-demo-account" ))+" ")]),_c('div',{staticClass:"h-7 flex items-center absolute top-5 right-5",on:{"click":function($event){return _vm.$emit('close')}}},[_c('button',{staticClass:"text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none",attrs:{"aria-label":"Close panel"}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 18L18 6M6 6l12 12","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2"}})])])])])]),(_vm.$store.getters.get_demo_backend_accounts.length > 0)?_c('div',{staticClass:"relative flex-1 mt-0 sm:p-0 p-6"},[_c('div',[_c('div',{staticClass:"sm:px-0 pt-8"},[_c('dl',[_c('div',{staticClass:"sm:px-8 sm:py-2"},[_c('dt',{staticClass:"text-sm leading-5 font-normal mb-1"},[_vm._v(" "+_vm._s(_vm.$t("select-account"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2 flex"},_vm._l((_vm.get_demo_backend_accounts),function(account,index){return _c('button',{key:index,staticClass:"transition cursor-pointer duration-150 ease-in-out p-5 border rounded mr-3 w-full focus:outline-none",class:_vm.selectedAccount === account
                                    ? 'border-blue-500'
                                    : '',attrs:{"id":"account_1","type":"reset"},on:{"click":function($event){return _vm.setAccount(account)}}},[_c('img',{staticClass:"m-auto",attrs:{"src":_vm.selectedAccount === account
                                      ? require(
                                          ("../../assets/" + (account.backend.type) + "-white.png")
                                        )
                                      : require(
                                          ("../../assets/" + (account.backend.type) + ".png")
                                        ),"alt":"Meta Logo"}})])}),0)])]),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Currency"))+" ")]),_c('dd',{staticClass:"text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[(_vm.selectedAccount.backend)?_c('ValidationProvider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.get_demo_backend_accounts.find(
                                      function (x) { return x.backend === _vm.selectedAccount.backend; }
                                    ).currencies,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"label":"symbol"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)])]),(
                            _vm.whitelabel !== 'TDSouthAfrica' &&
                            _vm.whitelabel !== 'EagleGlobalMarkets' &&
                            _vm.whitelabel !== 'OneTradingMarkets' &&
                            _vm.whitelabel !== 'TradeCoreUK' &&
                            _vm.whitelabel !== 'VCGMarkets' &&
                            _vm.whitelabel !== 'PolarisMarkets' &&
                            _vm.whitelabel !== 'Dzengi'
                          )?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("account-type"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"account_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.accountTypes,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"label":"symbol"},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e(),(
                            _vm.whitelabel === 'ICMEU' ||
                            _vm.whitelabel === 'ICMTrader' ||
                            _vm.whitelabel === 'ICMCapital' ||
                            _vm.whitelabel === 'ICMMENA' ||
                            _vm.whitelabel === 'TradiNext'
                          )?_c('div',{staticClass:"sm:px-8 mt-5 mb-1"},[_c('dt',{staticClass:"text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("Leverage"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[_c('ValidationProvider',{attrs:{"name":"leverage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.leverages,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"label":"name"},model:{value:(_vm.leverage),callback:function ($$v) {_vm.leverage=$$v},expression:"leverage"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e(),(
                            _vm.whitelabel !== 'TDSouthAfrica' &&
                            _vm.whitelabel !== 'EagleGlobalMarkets' &&
                            _vm.whitelabel !== 'OneTradingMarkets' &&
                            _vm.whitelabel !== 'TradeCoreUK' &&
                            _vm.whitelabel !== 'VCGMarkets'
                          )?_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"text-sm leading-5 font-normal sm:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t("deposit-amount"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"},[_c('div',{staticClass:"sm:col-span-3"},[(
                                  (_vm.whitelabel === 'ICMVC' ||
                                    _vm.whitelabel === 'ICMMU') &&
                                  _vm.accountType === 'ICM Micro'
                                )?_c('ValidationProvider',{attrs:{"name":"deposit_amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.depositAmountsICMCent,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"label":"name"},model:{value:(_vm.depositAmount),callback:function ($$v) {_vm.depositAmount=$$v},expression:"depositAmount"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_c('ValidationProvider',{attrs:{"name":"deposit_amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.depositAmounts,"placeholder":_vm.$t(
                                      'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                    ),"label":"name"},model:{value:(_vm.depositAmount),callback:function ($$v) {_vm.depositAmount=$$v},expression:"depositAmount"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]):_vm._e()])])])]):_vm._e()]),(_vm.$store.getters.get_demo_backend_accounts.length > 0)?_c('div',{staticClass:"flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm ml-3"},[_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50",attrs:{"type":"reset"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])]),_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('Button',{staticClass:"relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"disabled":_vm.isDisabled,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("create-account"))+" ")])],1)]):_vm._e()])])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed inset-0 transition-opacity"},[_c('div',{staticClass:"absolute inset-0 bg-black opacity-50"})])}]

export { render, staticRenderFns }