<template>
  <nav
    :class="{ 'navigation-white': !showWhiteLogo }"
    class="nav-bg navigation-vue"
  >
    <div class="max-w-7xl mx-auto px-2 p-0">
      <div class="relative flex items-center justify-between h-16">
        <div v-if="$store.getters.is_logged_in" class="flex md:hidden">
          <div
            class="-ml-2 mr-2 flex items-center"
            @click="CHANGE_SHOW_SIDEBAR"
          >
            <!-- Mobile menu button -->
            <button
              aria-expanded="false"
              aria-label="Main menu"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-400 hover:bg-white focus:outline-none focus:bg-bg-white focus:text-gray-400 transition duration-150 ease-in-out"
            >
              <!-- Icon when menu is closed. -->
              <!--
                Heroicon name: menu

                Menu open: "hidden", Menu closed: "block"
              -->
              <svg
                class="block h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6h16M4 12h16M4 18h16"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
              <!-- Icon when menu is open. -->
              <!--
                Heroicon name: x

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg
                class="hidden h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6l12 12"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="!showWhiteLogo"
          class="flex items-center sm:items-stretch sm:justify-start"
        >
          <a
              v-if="logoName === 'Dzengi'"
              href="/"
          >
            <img
                :src="require(`../assets/Dzengi/logo-icon.png`)"
                alt="Logo"
                class="md:block h-10 md:h-12 w-auto"
            />
          </a>
          <a
              v-if="logoName.includes('Polaris')"
              href="/"
          >
            <img
                :src="require(`../assets/PolarisMarkets-logo.png`)"
                alt="logo"
                class="md:block h-10 md:h-14 w-auto"
            />
          </a>
          <a
            v-if="logoName.includes('ICMTrader')"
            href="https://www.icmtrader.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/ICMTrader-logo.svg`)"
              alt="logo"
              class="md:block h-14 w-auto"
            />
          </a>
          <div v-else>
            <a
              v-if="logoName === 'ICMMENA'"
              href="http://www.icmmena.com"
              target="_blank"
            >
              <img
                :src="require(`../assets/icmmena-logo.svg`)"
                alt="logo"
                class="md:block h-auto w-24"
              />
            </a>
            <a
              v-if="logoName.includes('GCCBrokers')"
              href="https://www.gccbrokers.com/"
              target="_blank"
            >
              <img
                :src="require(`../assets/GCCBrokers-logo.png`)"
                alt="logo"
                class="md:block h-auto w-12"
              />
            </a>
            <a
              v-if="logoName.includes('OrbitInvest')"
              href="http://www.orbit-invest-trade.com/"
              target="_blank"
            >
              <img
                :src="require(`../assets/OrbitInvest-logo.png`)"
                alt="logo"
                class="md:block h-auto w-24"
              />
            </a>
            <a
              v-if="logoName === 'ICMAP'"
              href="https://www.icmcapital.com"
              target="_blank"
            >
              <img
                :src="require(`../assets/icm-logo.svg`)"
                alt="logo"
                class="md:block h-auto w-auto"
              />
            </a>
            <a
              v-if="logoName === 'ICMVC' || logoName === 'ICMMU'"
              href="https://www.icm.com/"
              target="_blank"
            >
              <img
                :src="require(`../assets/icm-logo.svg`)"
                alt="logo"
                class="md:block h-auto w-auto"
              />
            </a>
            <a
              v-if="logoName === 'ICMCapital'"
              href="https://icmcapital.co.uk/"
              target="_blank"
            >
              <img
                :src="require(`../assets/icm-logo.svg`)"
                alt="logo"
                class="md:block h-auto w-auto"
              />
            </a>
            <a
              v-if="logoName.includes('Promena')"
              href="https://www.promenamarkets.com/en"
              target="_blank"
            >
              <img
                :src="require(`../assets/${logoName}-logo.png`)"
                alt="logo"
                class="md:block h-auto w-32"
              />
            </a>
            <a
              v-if="logoName === 'ICMEU'"
              href="https://www.icmcapital.co.uk/"
              target="_blank"
            >
              <img
                :src="require(`../assets/${logoName}-logo.png`)"
                alt="logo"
                class="md:block h-auto w-auto"
              />
            </a>
            <a
              v-if="logoName === 'Blackstone'"
              href="https://blackstonefutures.co.za/"
              target="_blank"
            >
              <img
                :src="require(`../assets/${logoName}-logo.png`)"
                alt="logo"
                class="md:block h-auto w-52"
              />
            </a>
            <a
              v-if="logoName.includes('OneTradingMarkets')"
              href="#"
              target="_blank"
            >
              <img
                :src="require(`../assets/OneTradingMarkets-logo.png`)"
                alt="logo"
                class="md:block h-auto w-32"
              />
            </a>
            <a v-if="logoName.includes('VCGMarkets')" href="/">
              <img
                :src="require(`../assets/VCGMarkets_new_black_logo.svg`)"
                alt="logo"
                class="md:block h-14 w-auto p-2"
              />
            </a>
            <a
              v-if="logoName.includes('EagleGlobalMarkets')"
              href="https://eagleglobalmarkets.com/"
              target="_blank"
            >
              <img
                :src="require(`../assets/EagleGlobalMarkets-logo.svg`)"
                alt="logo"
                class="md:block h-auto w-32"
              />
            </a>
            <a v-if="logoName === 'TradeCoreUK'" href="#" target="_blank">
              <img
                :src="require(`../assets/dxtrade-logo.svg`)"
                alt="logo"
                class="md:block h-auto w-32"
              />
            </a>
            <a
              v-if="logoName === 'TradiNext'"
              href="https://tradinext.com/"
              target="_blank"
            >
              <img
                :src="require(`../assets/TradiNext-logo.png`)"
                alt="logo"
                class="md:block h-auto w-32"
              />
            </a>
            <a
              v-if="logoName === 'TD365' || logoName === 'TDSouthAfrica'"
              :href="
                logoName === 'TD365'
                  ? 'https://tradedirect365.com.au/'
                  : 'https://td365.com/'
              "
              target="_blank"
            >
              <img
                :src="require(`../assets/${logoName}-logo.png`)"
                alt="logo"
                class="md:block h-auto w-52"
              />
            </a>
          </div>
        </div>
        <div
          v-if="showWhiteLogo"
          class="flex items-center sm:items-stretch sm:justify-start"
        >
          <a
            v-if="logoName === 'ICMMENA'"
            href="http://www.icmmena.com"
            target="_blank"
          >
            <img
              :src="require(`../assets/icmmena-logo.svg`)"
              alt="logo"
              class="md:block h-auto w-24"
            />
          </a>
          <a
            v-if="logoName.includes('GCCBrokers')"
            href="https://www.gccbrokers.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/GCCBrokers-logo.png`)"
              alt="logo"
              class="md:block h-auto w-12"
            />
          </a>
          <a
            v-if="logoName.includes('OrbitInvest')"
            href="http://www.orbit-invest-trade.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/OrbitInvest-logo.png`)"
              alt="logo"
              class="md:block h-auto w-24"
            />
          </a>
          <a
            v-if="logoName === 'ICMEU'"
            href="https://www.icmcapital.co.uk/"
            target="_blank"
          >
            <img
              :src="require(`../assets/icm-white.svg`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a
            v-if="logoName === 'ICMAP'"
            href="https://www.icmcapital.com"
            target="_blank"
          >
            <img
              :src="require(`../assets/icm-white.svg`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a
            v-if="logoName === 'ICMVC' || logoName === 'ICMMU'"
            href="https://www.icm.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/icm-white.svg`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a
            v-if="logoName === 'ICMCapital'"
            href="https://icmcapital.co.uk/ "
            target="_blank"
          >
            <img
              :src="require(`../assets/icm-white.svg`)"
              alt="logo"
              class="md:block h-auto w-auto"
            />
          </a>
          <a
            v-if="logoName.includes('Promena')"
            href="https://www.promenamarkets.com/en"
            target="_blank"
          >
            <img
              :src="require(`../assets/${logoName}-white.png`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a
            v-if="logoName.includes('ICMTrader')"
            href="https://www.icmtrader.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/ICMTrader-white.svg`)"
              alt="logo"
              class="md:block h-14 w-auto"
            />
          </a>
          <a
            v-if="logoName.includes('Blackstone')"
            href="https://blackstonefutures.co.za/"
            target="_blank"
          >
            <img
              :src="require(`../assets/Blackstone-logo.png`)"
              alt="logo"
              class="md:block h-auto w-52"
            />
          </a>
          <a
            v-if="logoName.includes('OneTradingMarkets')"
            href="#"
            target="_blank"
          >
            <img
              :src="require(`../assets/OneTradingMarkets-logo.png`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a
            v-if="logoName.includes('EagleGlobalMarkets')"
            href="https://eagleglobalmarkets.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/EagleGlobalMarkets-white.svg`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a v-if="logoName === 'TradeCoreUK'" href="#" target="_blank">
            <img
              :src="require(`../assets/DXTrade-white.png`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a
            v-if="logoName === 'TradiNext'"
            href="https://tradinext.com/"
            target="_blank"
          >
            <img
              :src="require(`../assets/TradiNext-logo.png`)"
              alt="logo"
              class="md:block h-auto w-32"
            />
          </a>
          <a
            v-if="logoName === 'TD365'"
            :href="
              logoName === 'TD365'
                ? 'https://tradedirect365.com.au/'
                : 'https://td365.com/'
            "
            target="_blank"
          >
            <img
              :src="require(`../assets/${logoName}-white.png`)"
              alt="logo"
              class="md:block h-auto w-52"
            />
          </a>
          <a
            v-if="logoName === 'TDSouthAfrica'"
            :href="
              logoName === 'TD365'
                ? 'https://tradedirect365.com.au/'
                : 'https://td365.com/'
            "
            target="_blank"
          >
            <img
              :src="require(`../assets/${logoName}-white.svg`)"
              alt="logo"
              class="md:block h-auto w-52"
            />
          </a>
        </div>
        <div class="flex items-center sm:items-stretch sm:justify-start">
          <user-menu v-if="showUserMenu" :navigation="true"></user-menu>
        </div>
        <div
          v-if="showLanguageSwitcher"
          class="flex inset-y-0 justify-end right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 w-full sm:w-1/2"
        >
          <div
            v-if="
              whitelabel !== 'EagleGlobalMarkets' &&
              whitelabel !== 'OrbitInvest' &&
              whitelabel !== 'TradeCoreUK'
            "
            class="language-switcher mr-3 w-full sm:w-1/3 hidden sm:block"
          >
            <language-switcher :bottom="true"></language-switcher>
          </div>
          <div
            v-show="showLogin"
            class="login cursor-pointer sm:mr-12 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white focus:outline-none transition ease-in-out duration-150"
            @click="login"
          >
            {{ $t("Login") }}
          </div>
        </div>
      </div>
      <!-- language switcher for small screen that will be shown bellow logo and login -->
      <div
        v-if="
          showLanguageSwitcher &&
          whitelabel !== 'EagleGlobalMarkets' &&
          whitelabel !== 'OrbitInvest' &&
          whitelabel !== 'TradeCoreUK'
        "
        class="language-switcher w-full sm:w-1/3 block sm:hidden pb-4"
      >
        <language-switcher :bottom="true"></language-switcher>
      </div>
      <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
      <Sidebar v-if="get_show_sidebar"></Sidebar>
    </div>
  </nav>
</template>
<script>
import Sidebar from "@/components/Sidebar";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import UserMenu from "@/components/UserMenu";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Navigation",
  components: {
    Sidebar,
    LanguageSwitcher,
    UserMenu,
  },
  data() {
    return {
      logoName: process.env.VUE_APP_WHITELABEL,
      showMobileMenu: false,
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters(["get_show_sidebar"]),
  },
  props: {
    showLogin: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    showUserMenu: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    showWhiteLogo: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    showLanguageSwitcher: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    ...mapMutations(["CHANGE_SHOW_SIDEBAR"]),
    login() {
      if (
        this.$route.path.includes("ib") ||
        process.env.VUE_APP_WHITELABEL === "Promena"
      ) {
        this.$router.push("/ib/login");
      } else {
        this.$router.push("/auth/login");
      }
    },
  },
};
</script>
